'use client'
export default function NotFoundPage (

)
{
  return (
    <div className="size-full align-middle text-title-xl2 items-center justify-center self-center">

      404 Page Not Found

    </div>
  )
}
